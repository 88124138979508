import React, { useEffect, useState, useCallback } from "react";

import { Card, Row, Col, Switch, Button, Upload, Form, Modal, Popconfirm, Input, Typography, Space, message } from "antd";
import { UploadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";

const { Text } = Typography;

function Templates() {
  const [templates, setTemplates] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addTemplateForm] = Form.useForm();
  const [addTemplateLoading, setAddTemplateLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const authenticatedFetch = useAuthenticatedFetch();

  const [dateStamp] = useState(+new Date());

  useEffect(() => {
    if (!templates) {
      authenticatedFetch("/api/templates")
        .then((r) => r.json())
        .then((response) => {
          if (!response.error) setTemplates(response);
        });
    }
  }, [templates, authenticatedFetch]);

  function activateTemplate(template, checked) {
    authenticatedFetch(`/api/templates/${template.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({ active: checked }),
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          setTemplates([...templates.map((t) => (template.id === t.id ? response : t))]);
        }
      });
  }

  function deleteTemplate(template) {
    authenticatedFetch(`/api/templates/${template.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          setTemplates([...templates.filter((tmpl) => template.id !== tmpl.id)]);
        }
      });
  }

  const validateZip = useCallback((file) => {
    if (file.type !== "application/zip") {
      message.error(`${file.name} is not a zip file`);
    }
    return file.type === "application/zip" ? true : Upload.LIST_IGNORE;
  }, []);

  const showModal = (template) => {
    console.log("Show Modal");
    if (template) {
      setSelectedTemplate(template);
      addTemplateForm.setFieldsValue({ title: template.data?.title ?? "", description: template.data?.description ?? "" });
    }
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    console.log("Modal Cancel");
    setIsModalVisible(false);
    addTemplateForm.resetFields();
    setSelectedTemplate(null);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleAddTemplateFinish = (values) => {
    console.log("Received values of form: ", values);
    setAddTemplateLoading(true);
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("file", values.file[0].originFileObj);
    const method = selectedTemplate ? "PUT" : "POST";
    authenticatedFetch(`/api/templates/${selectedTemplate ? selectedTemplate.id : ""}`, {
      method,
      body: formData,
    })
      .then((r) => r.json())
      .then((response) => {
        if (!response.error) {
          if (selectedTemplate)
            setTemplates([
              ...templates.map((t) => {
                if (t.id === selectedTemplate.id) return { ...t, data: { ...t.data, title: response.data.title, description: response.data.description } };
                return t;
              }),
            ]);
          else setTemplates([...templates, response]);
        }
        setAddTemplateLoading(false);
        setIsModalVisible(false);
        setSelectedTemplate(null);
        addTemplateForm.resetFields();
      });
  };

  const addTemplateMarkup = (
    <Modal
      title="Add Template"
      okText="Submit"
      visible={isModalVisible}
      onOk={addTemplateForm.submit}
      onCancel={handleModalCancel}
      okButtonProps={{
        loading: addTemplateLoading,
      }}
    >
      <Form
        name="add_template"
        onFinish={handleAddTemplateFinish}
        form={addTemplateForm}
        labelCol={{
          xs: { span: 24 },
          sm: { span: 5 },
        }}
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
        }}
      >
        <Form.Item name="title" label="Name" rules={[{ required: true, message: "Please provide a template name" }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true, message: "Please provide a template description" }]}>
          <Input.TextArea showCount maxLength={100} />
        </Form.Item>
        <Form.Item
          name="file"
          label="Zip File"
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={[{ required: true, message: "Please provide the template zip file" }]}
        >
          <Upload.Dragger accept=".zip" maxCount={1} beforeUpload={validateZip} customRequest={dummyRequest}>
            <Space direction="vertical">
              <UploadOutlined style={{ color: "#40a9ff", fontSize: "40px" }} />
              <Text>Click or drag file to this area to upload</Text>
              <Text type="secondary">Support for a single zip file containing the template files.</Text>
            </Space>
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );

  return (
    <div className="templates">
      {addTemplateMarkup}
      <Card
        title="Templates"
        extra={
          <Button type="link" onClick={() => showModal()}>
            Add Template
          </Button>
        }
      >
        <Row gutter={[16, 16]}>
          {templates &&
            templates.map((template) => (
              <Col xs={24} sm={12} md={8} lg={6} key={template.id}>
                <Card
                  type="inner"
                  cover={
                    template.data && (
                      <img
                        src={`https://storage.googleapis.com/gm-assets/templates/${template.id}/preview.jpg?t=${dateStamp}`}
                        alt={template.data?.title ?? ""}
                      />
                    )
                  }
                  actions={[
                    <Switch
                      defaultChecked={template.active}
                      onChange={(checked) => activateTemplate(template, checked)}
                      checkedChildren="Active"
                      unCheckedChildren="Inactive"
                      key="active"
                    />,
                    <EditOutlined key="edit" onClick={() => showModal(template)} />,
                    <Popconfirm
                      key="delete_confirm"
                      title="Are you sure to delete this template?"
                      onConfirm={() => deleteTemplate(template)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined />
                    </Popconfirm>,
                  ]}
                >
                  <Card.Meta className="template" title={template.data?.title ?? ""} description={template.data?.description ?? ""} />
                </Card>
              </Col>
            ))}
        </Row>
      </Card>
    </div>
  );
}

export default Templates;
