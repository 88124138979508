import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Card, Row, Col, Button, Form, List, Typography, Divider, Table } from "antd";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";

const { Title } = Typography;

function Credits() {
  const authenticatedFetch = useAuthenticatedFetch();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const queryConfirm = query.get("confirm");

  const [loaded, setLoaded] = useState(false);
  const [purchased, setPurchased] = useState(null);
  const [deducted, setDeducted] = useState(null);
  const [balance, setBalance] = useState(null);
  const [creditBundles, setCreditBundles] = useState(null);
  const [creditCharges, setCreditCharges] = useState(null);

  const [purchasing, setPurchasing] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      authenticatedFetch("/api/credits")
        .then((r) => r.json())
        .then((response) => {
          setPurchased(response.purchased);
          setDeducted(response.deducted);
          setBalance(response.balance);
          setCreditBundles(response.bundles);
          setCreditCharges(response.credit_charges);
        })
        .then(() => {
          setLoaded(true);
        });
    }
  }, [authenticatedFetch, loaded]);

  useEffect(() => {
    if (queryConfirm) {
      authenticatedFetch(`/api/credits/${encodeURIComponent(queryConfirm)}/confirm`)
        .then((r) => r.json())
        .then(() => {
          console.log("Confirmed credit charge.");
          setLoaded(false);
          history.replace(`/credits`, history.location.state);
        });
    }
  }, [authenticatedFetch, history, queryConfirm]);

  const handleCreditPurchase = useCallback(
    (id) => {
      setPurchasing(true);
      authenticatedFetch("/api/credits", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
        }),
        method: "POST",
      })
        .then((r) => r.json())
        .then((response) => {
          if (response.redirect_to) {
            console.log("handleCreditPurchase redirect to response.redirect_to");
            window.location.href = response.redirect_to;
          }
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("Failed to purchase credit data.");
        })
        .then(() => {
          setPurchasing(false);
        });
    },
    [authenticatedFetch]
  );

  return (
    <div className="credits">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title>Credit Balance: {balance}</Title>

          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={creditBundles || []}
            renderItem={(item) => (
              <List.Item>
                <Card title={`${item.amount}`}>
                  {`${item.amount} Credits for $${item.price}`}
                  {item.plan_id ? "Exlusive!" : ""}
                  <Form onFinish={() => handleCreditPurchase(item.id)}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={purchasing}>
                        Purchase
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </List.Item>
            )}
          />

          <Divider />

          <Table
            title={() => <Title level={3}>Additions</Title>}
            columns={[
              { title: "Id", dataIndex: "id", key: "id" },
              { title: "Date", dataIndex: "date", key: "date" },
              { title: "Amount", dataIndex: "amount", key: "amount" },
              { title: "Used", dataIndex: "used", key: "used" },
              { title: "Type", dataIndex: "type", key: "type" },
              { title: "Expiry", dataIndex: "expiry", key: "expiry" },
            ]}
            dataSource={
              purchased &&
              purchased.map((credit) => ({
                key: credit.id,
                id: credit.id,
                date: credit.created_at,
                amount: credit.amount,
                used: credit.used,
                type: credit.credit_type,
                expiry: credit.expiry ? credit.expiry : "N/A",
              }))
            }
          />

          <Divider />

          <Table
            title={() => <Title level={3}>Usage History</Title>}
            columns={[
              { title: "Id", dataIndex: "id", key: "id" },
              { title: "Date", dataIndex: "date", key: "date" },
              { title: "Amount", dataIndex: "amount", key: "amount" },
              { title: "Credit ID Decucted From", dataIndex: "credit_id", key: "credit_id" },
              { title: "Type", dataIndex: "type", key: "type" },
              { title: "Reason", dataIndex: "reason", key: "reason" },
            ]}
            dataSource={
              deducted &&
              deducted.map((credit) => ({
                key: credit.id,
                id: credit.id,
                date: credit.created_at,
                amount: credit.amount,
                credit_id: credit.credit_id ? credit.credit_id : "N/A",
                type: credit.credit_type,
                reason: (credit.card_id && "Card") || (credit.expired && "Expired") || credit.reason || "N/A",
              }))
            }
          />

          <Divider />

          <Table
            title={() => <Title level={3}>Purchase History</Title>}
            columns={[
              { title: "Date", dataIndex: "date", key: "date" },
              { title: "Price", dataIndex: "price", key: "price" },
              { title: "Credits", dataIndex: "amount", key: "amount" },
              { title: "Status", dataIndex: "status", key: "status" },
              { title: "", dataIndex: "confirm", key: "confirm" },
            ]}
            dataSource={
              creditCharges &&
              creditCharges.map((charge) => ({
                key: charge.id,
                date: charge.created_at,
                amount: charge.amount,
                price: charge.price,
                status: charge.status,
                confirm: charge.confirmed ? "" : <Button href={charge.confirmation_url}>Confirm Charge</Button>,
              }))
            }
          />
        </Col>
      </Row>
    </div>
  );
}

export default Credits;
