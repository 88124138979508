import React, { createContext, useContext, useEffect, useState } from "react";

import { useAuthenticatedFetch } from "../AuthenticatedFetchProvider";

export const SubscriptionContext = createContext({ plans: [], activePlan: null, activeSubscriptions: [] });

const GetPlans = () => useContext(SubscriptionContext).plans;
const GetActivePlan = () => useContext(SubscriptionContext).activePlan;
const GetSubscriptions = () => useContext(SubscriptionContext).activeSubscriptions;

export const SubscriptionProvider = ({ children }) => {
  const authenticatedFetch = useAuthenticatedFetch();
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded === false) {
      authenticatedFetch("/api/subscriptions")
        .then((r) => r.json())
        .then((response) => {
          setPlans(response.plans.sort((a, b) => a.display_ranking - b.display_ranking));
          setActivePlan(response.plans.filter((p) => p.id === response.active_plan_id)[0]);
          setActiveSubscriptions(response.active_subscriptions);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.error("can't fetch subscriptions... uh oh.");
        })
        .then(() => {
          setLoaded(true);
        });
    }
  }, [authenticatedFetch, loaded]);

  return (
    <SubscriptionContext.Provider
      value={{
        plans,
        activePlan,
        activeSubscriptions,
        loaded,
        reload: () => {
          setLoaded(false);
        },
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default {
  GetPlans,
  GetActivePlan,
  GetSubscriptions,
};
