import React, { useState, useCallback, useEffect } from "react";

import { useParams } from "react-router-dom";
import Editor from "@monaco-editor/react";
import JSZip from "jszip";
import { Button, Tabs } from "antd";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";

const TEMPLATE_STATIC_BASE = "https://storage.googleapis.com/gm-assets/templates/";

function AssetUploader({ templateId, assetKey }) {
  const authenticatedFetch = useAuthenticatedFetch();

  const upload = useCallback(
    (e) => {
      const formData = new FormData();
      formData.append("file", e.target.current.files[0]);
      authenticatedFetch(`/api/templates/${templateId}/asset?key=${encodeURIComponent(assetKey)}`, {
        method: "PUT",
        body: formData,
      });
    },
    [authenticatedFetch, templateId, assetKey]
  );

  return <input type="file" onChange={upload} />;
}

function monacoLanguageFromContentType(contentType) {
  if (contentType === "application/javascript") return "javascript";
  if (contentType === "text/css") return "css";
  if (contentType === "text/html") return "html";
  return "text";
}

function EditAsset({ templateId, assetKey, contentType }) {
  const [loadedAssetKey, setLoadedAssetKey] = useState(false);
  const [content, setContent] = useState(null);
  const authenticatedFetch = useAuthenticatedFetch();

  useEffect(() => {
    if (assetKey) {
      fetch(`${TEMPLATE_STATIC_BASE}${templateId}/${assetKey}?v=${+new Date()}`)
        .then((r) => r.text())
        .then((response) => {
          setContent(response);
        })
        .finally(() => {
          setLoadedAssetKey(assetKey);
        });
    }
  }, [templateId, assetKey]);

  const save = useCallback(() => {
    const formData = new FormData();
    formData.append("file", new Blob([content]));
    authenticatedFetch(`/api/templates/${templateId}/asset?key=${encodeURIComponent(assetKey)}`, {
      method: "PUT",
      body: formData,
    });
  }, [content, authenticatedFetch, templateId, assetKey]);

  useEffect(() => {
    const isMac = window.navigator.platform.match("MacIntel");
    const cb = (e) => {
      if ((isMac ? e.metaKey : e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault();
        save();
      }
    };
    document.addEventListener("keydown", cb, false);
    return () => {
      document.removeEventListener("keydown", cb, false);
    };
  }, [save]);

  return (
    loadedAssetKey && (
      <>
        <Button onClick={save}>save</Button> {contentType} is {monacoLanguageFromContentType(contentType)}
        <Editor height="90vh" language={monacoLanguageFromContentType(contentType)} value={content} onChange={setContent} />
      </>
    )
  );
}

function TemplateEditor() {
  const { id } = useParams();
  const authenticatedFetch = useAuthenticatedFetch();

  const [template, setTemplate] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      authenticatedFetch(`/api/templates/${id}`)
        .then((r) => (r.ok ? r.json() : Promise.reject(r)))
        .then((response) => {
          setTemplate(response);
          setLoaded(true);
        })
        .catch((ex) => {
          console.warn(ex);
        });
    }
  }, [loaded, id, authenticatedFetch]);

  const upload = useCallback(
    (e) => {
      const blob = e.target.files[0];
      if (blob) {
        const uploadForm = new FormData();
        uploadForm.append("file", blob, "file");

        return authenticatedFetch(`/api/templates/${id}`, {
          method: "PUT",
          body: uploadForm,
        }).finally(() => {
          setLoaded(false);
          e.target.value = "";
        });
      }
      return Promise.resolve();
    },
    [authenticatedFetch, id]
  );

  const downloadZip = useCallback(async () => {
    const zip = new JSZip();
    await Promise.all(
      template.assets.map(async ({ key }) => {
        const response = await fetch(`${TEMPLATE_STATIC_BASE}${id}/${key}`);
        if (response.ok) {
          zip.file(key, await response.blob());
        }
      })
    );
    const fileBlob = await zip.generateAsync({ type: "blob" });
    const uri = URL.createObjectURL(fileBlob);
    window.open(uri);
    setTimeout(() => {
      URL.revokeObjectURL(uri);
    }, 10000);
  }, [id, template]);

  return (
    template && (
      <>
        <h2>
          Template <code>{template.id}</code>
        </h2>
        <p>
          <Button onClick={downloadZip}>Download ZIP</Button>
        </p>
        <input type="file" accepts="application/zip" onChange={upload} />
        <Tabs defaultActiveKey="template.html">
          {template.assets.map(({ key, content_type: contentType }) => {
            const isText = /^(?:application\/javascript\b|text\/)/.test(contentType);
            return (
              <Tabs.TabPane tab={key} key={key}>
                <a href={`${TEMPLATE_STATIC_BASE}${id}/${key}`} target="_blank" rel="noreferrer">
                  Open
                </a>
                <AssetUploader templateId={id} assetKey={key} />
                {isText && <EditAsset key={`${id}/${key}`} templateId={id} assetKey={key} contentType={contentType} />}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </>
    )
  );
}

export default TemplateEditor;
