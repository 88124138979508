/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext, useState, useCallback, useEffect } from "react";
import { DateTime } from "luxon";
import { Card, Spin, Button, Modal, Typography, Table } from "antd";
import { useLocation, useHistory } from "react-router-dom";

import { SubscriptionContext } from "../contexts/SubscriptionContext";
import { useAuthenticatedFetch } from "../AuthenticatedFetchProvider";

const { Text } = Typography;

function SubscriptionHisoryTable() {
  const { activeSubscriptions, reload } = useContext(SubscriptionContext);
  const query = new URLSearchParams(useLocation().search);
  const queryConfirm = query.get("confirm");
  const history = useHistory();
  const authenticatedFetch = useAuthenticatedFetch();

  const [activeModal, setActiveModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleActive = useCallback(() => setActiveModal(!activeModal), [activeModal]);

  useEffect(() => {
    if (queryConfirm) {
      setLoadingTable(true);
      authenticatedFetch(`/api/subscriptions/${encodeURIComponent(queryConfirm)}/confirm`)
        .then((r) => r.json())
        .then((r) => {
          if (r.error) {
            console.log("Failed to confirm charge");
          } else {
            console.log("Confirmed charge.");
            reload();
            history.replace(`/account`, history.location.state);
          }
        })
        .catch(() => {})
        .finally(() => {
          setLoadingTable(false);
        });
    }
  }, [authenticatedFetch, history, queryConfirm, reload]);

  const confirmCancelModal = (subscription) => {
    setSelectedSubscription(subscription);
    handleActive();
  };

  const handleConfirmCancel = (subscription) => {
    setLoadingButton(true);
    setLoadingTable(true);
    authenticatedFetch(`api/subscriptions/${subscription.id}/unsubscribe`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const unsubscribeData = await response.json();
        // check for error response
        if (!response.ok) {
          console.log("Error handling uninstall request");
          // get error message from body or default to response status
          const error = (unsubscribeData && unsubscribeData.message) || response.status;
          throw error;
        }
        console.log("Uninstall finished successfully");
      })
      .catch((error) => {
        console.error("Error handling uninstall request", error);
      })
      .finally(() => {
        setSelectedSubscription(null);
        setLoadingButton(false);
        reload();
        setLoadingTable(false);
        handleActive();
      });
  };

  const handleConfirmPending = (subscription) => {
    console.log(`Redirecting to charge confirmation url for subscription ${subscription.id}`);
    window.location.href = subscription.confirmation_url;
  };

  const activeSubscriptionRows = (activeSubscription) =>
    activeSubscription &&
    activeSubscription.length > 0 &&
    activeSubscription.map((subscription) => ({
      name: subscription.name,
      status: subscription.state,
      amount: `$${subscription.cost}`,
      billing: "Monthly",
      created: DateTime.fromISO(subscription.date_subscribed).toString(),
      trial_days: subscription.trial_days_left,
      cancelled: subscription.date_unsubscribed ? subscription.date_unsubscribed : "N/A",
      actions: subscription.active ? (
        <Button danger onClick={() => confirmCancelModal(subscription)}>
          Cancel
        </Button>
      ) : (
        <Button onClick={() => handleConfirmPending(subscription)} type="primary">
          Activate
        </Button>
      ),
    }));

  useEffect(() => {
    if (activeSubscriptions !== null) {
      setLoadingTable(false);
    }
  }, [activeSubscriptions]);

  return activeSubscriptions && activeSubscriptions.length > 0 ? (
    <Card
      title="Subscription History"
      extra={
        <Button type="link" onClick={reload}>
          Reload
        </Button>
      }
    >
      <Modal
        title="Cancel Subscription?"
        okText="Cancel"
        visible={activeModal}
        onOk={() => handleConfirmCancel(selectedSubscription)}
        onCancel={handleActive}
        okButtonProps={{
          danger: true,
          loading: loadingButton,
        }}
      >
        <Text>Are you sure you want to cancel your subscription? This action can’t be undone.</Text>
      </Modal>
      {loadingTable ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={[
            { title: "Name", dataIndex: "name", key: "name" },
            { title: "Status", dataIndex: "status", key: "status" },
            { title: "Amount", dataIndex: "amount", key: "amount" },
            { title: "Billing", dataIndex: "billing", key: "billing" },
            { title: "Created", dataIndex: "created", key: "created" },
            { title: "Trial Days Left", dataIndex: "trial_days", key: "trial_days" },
            { title: "Cancelled", dataIndex: "cancelled", key: "cancelled" },
            { title: "", dataIndex: "actions", key: "actions" },
          ]}
          dataSource={activeSubscriptionRows(activeSubscriptions)}
        />
      )}
    </Card>
  ) : (
    <></>
  );
}

export default SubscriptionHisoryTable;
