import React from "react";
import { BrowserRouter, Switch, Route, Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { CardEditor } from "gift-messages-sdk";
import "gift-messages-sdk/dist/esm/styles.css";
import Debug from "./Debug";
import CardView from "./CardView";
import Templates from "./Templates";
import TemplateEditor from "./TemplateEditor";
import TokenProvider, { useTokenState } from "./AuthenticatedFetchProvider";
import Account from "./Account";
import Credits from "./Credits";
import Login from "./Login";

import { SubscriptionProvider } from "./contexts/SubscriptionContext";

import "./App.css";

import MerchantDashboard from "./MerchantDashboard";

function AppLayout({ children }) {
  const { pathname } = useLocation();
  const [token] = useTokenState();

  if (/\/(?:view|edit)\//.test(pathname)) {
    return children;
  }
  if (!token) {
    return (
      <Layout className="layout">
        <Layout.Content style={{ padding: "100px 50px" }}>
          <Login />
        </Layout.Content>
      </Layout>
    );
  }
  return (
    <Layout className="layout">
      <Layout.Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[window.location.pathname]}>
          <Menu.Item key="/">
            <Link to="/">Templates</Link>
          </Menu.Item>
          <Menu.Item key="/merchant_dashboard">
            <Link to="/merchant_dashboard">Merchant Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/account">
            <Link to="/account">Account</Link>
          </Menu.Item>
          <Menu.Item key="/credits">
            <Link to="/credits">Credits</Link>
          </Menu.Item>
          <Menu.Item key="/debug">
            <Link to="/debug">Debug</Link>
          </Menu.Item>
        </Menu>
      </Layout.Header>
      <Layout.Content style={{ padding: "0 50px" }}>{children}</Layout.Content>
      <Layout.Footer style={{ textAlign: "center" }}>©2022 Gift Messages by Eranzo Design</Layout.Footer>
    </Layout>
  );
}

function App() {
  return (
    <TokenProvider>
      <SubscriptionProvider>
        <BrowserRouter>
          <AppLayout>
            <Switch>
              <Route exact path="/" render={() => <Templates />} />
              <Route exact path="/debug" render={() => <Debug />} />
              <Route exact path="/edit/:id/:key" render={() => <CardEditor />} />
              <Route exact path="/view/:id" render={() => <CardView />} />
              <Route exact path="/merchant_dashboard" render={() => <MerchantDashboard />} />
              <Route exact path="/account" render={() => <Account />} />
              <Route exact path="/credits" render={() => <Credits />} />
              <Route exact path="/templates" render={() => <Templates />} />
              <Route exact path="/templates/:id" render={() => <TemplateEditor />} />
              <Route path="*" render={() => 404} />
            </Switch>
          </AppLayout>
        </BrowserRouter>
      </SubscriptionProvider>
    </TokenProvider>
  );
}

export default App;
