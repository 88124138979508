import React from "react";

import { Card, Row, Col, Button, Form, Input, Divider } from "antd";

function Login() {
  return (
    <Row>
      <Col span={6} offset={9}>
        <Card>
          <Form>
            <Form.Item>
              <Input label="Username" />
            </Form.Item>
            <Form.Item>
              <Input.Password label="Password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary">Submit</Button>
            </Form.Item>
          </Form>
          <Divider />
          <Button type="primary" href={`https://www.shopify.com/admin/apps/${process.env.REACT_APP_SHOPIFY_APP_ID}/core/account`}>
            Login with Shopify
          </Button>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
