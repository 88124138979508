/* eslint-disable no-console */
import React, { useCallback, useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Card, Row, Col, Button, Form, Input, Typography, Divider } from "antd";
import PlanFeatureMatrix from "./components/PlanFeatureMatrix";
import SubscriptionHistoryTable from "./components/SubscriptionHistoryTable";
import { SubscriptionContext } from "./contexts/SubscriptionContext";
import { useAuthenticatedFetch } from "./AuthenticatedFetchProvider";

const { Title } = Typography;

function Account() {
  const authenticatedFetch = useAuthenticatedFetch();
  const location = useLocation();

  const query = new URLSearchParams(location.search);

  const { reload } = useContext(SubscriptionContext);

  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeIsValid, setDiscountCodeIsValid] = useState(true);
  const [discountErrorMessage, setDiscountErrorMessage] = useState(null);
  const handleDiscountCodeChange = useCallback((value) => setDiscountCode(value), []);

  useEffect(() => {
    console.log("discountCodeIsValid and discountErrorMessage arn't used", discountCodeIsValid, discountErrorMessage);
  }, [discountCodeIsValid, discountErrorMessage]);

  const handleDiscountCodeSubmit = useCallback(() => {
    if (!discountCode) {
      setDiscountErrorMessage("Please enter a discount code");
      setDiscountCodeIsValid(false);
      return false;
    }
    if (discountCode.length < 10) {
      setDiscountErrorMessage("Discount codes must be at least 10 characters long");
      setDiscountCodeIsValid(false);
      return false;
    }

    console.log(`Initiating Subscribe action with discount code ${discountCode}`);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subscription: { discount_code: discountCode },
      }),
    };
    authenticatedFetch("/api/subscriptions", requestOptions)
      .then(async (response) => {
        const subscriptionsData = await response.json();
        // check for error response
        if (!response.ok) {
          console.log("Error handling subscribe request");
          // get error message from body or default to response status
          const error = (subscriptionsData && subscriptionsData.message) || response.status;
          throw error;
        }
        if (subscriptionsData.active_subscriptions) {
          console.log(`Successfully subscribed to plan`);
        }
        if (subscriptionsData.redirect_to) {
          window.location.href = subscriptionsData.redirect_to;
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setDiscountErrorMessage(error);
        setDiscountCodeIsValid(false);
      })
      .finally(() => {
        reload();
      });
    setDiscountCode("");
    return true;
  }, [authenticatedFetch, discountCode, reload]);

  const highlightFeature = query.get("highlightFeature");
  const featureValue = query.get("featureValue");

  return (
    <div className="account">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Title>Account</Title>

          <Card>
            <PlanFeatureMatrix highlightFeature={highlightFeature} featureValue={featureValue} />

            <Divider />

            <Row>
              <Col span={12}>
                <Form onFinish={() => handleDiscountCodeSubmit()}>
                  <Form.Item name="discount_code" label="Discount Code" rules={[{ required: true, message: "Please provide a discount code" }]}>
                    <Input id="discount_code" value={discountCode} onChange={handleDiscountCodeChange} label="Discount Code" />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>

            <Divider />

            <SubscriptionHistoryTable />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Account;
