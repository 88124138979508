import React, { useState, useCallback, useContext, createContext, useEffect, useRef, useMemo } from "react";
import jwtDecode from "jwt-decode";

const MERCHANT_UPDATE_TOKEN_KEY = "__gm_merchant_update_token_for_debugging__";
const TOKEN_RENEW_INTERVAL = 1 * 60 * 1000;

const AuthenticatedFetch = createContext(null);
const TokenState = createContext(null);

function getUpdateToken() {
  try {
    const qs = new URLSearchParams(window.location.search);
    const token = qs.get("token");
    if (token) {
      try {
        localStorage.setItem(MERCHANT_UPDATE_TOKEN_KEY, token);
        const linkElement = document.createElement("a");
        linkElement.setAttribute("href", window.location);
        qs.delete("token");
        linkElement.search = qs.toString();
        window.history.replaceState(window.history.state, "", linkElement.href);
      } catch (ex) {
        console.warn(ex);
        // fall through
      }
      return token;
    }
  } catch (ex) {
    console.warn(ex);
    // fall through
  }
  try {
    return window.localStorage.getItem(MERCHANT_UPDATE_TOKEN_KEY) ?? "";
  } catch (ex) {
    console.warn(ex);
    // fall through
  }
  return ""; // no token
}

function tokenIsValid(jwt) {
  try {
    const { exp } = jwtDecode(jwt);
    return exp * 1000 > +new Date() - 60000; // account for 60 seconds of clock skew
  } catch (ex) {
    return false;
  }
}

function TokenProvider({ children }) {
  const [token, setTokenState] = useState(getUpdateToken);
  const tokenRef = useRef(token);
  const setToken = useCallback((newToken) => {
    setTokenState(newToken);
    tokenRef.current = newToken;
  }, []);

  const authenticatedFetch = useCallback(async (url, options = {}, ...rest) => {
    const authorizationOverrides = { Authorization: `Bearer ${tokenRef.current}` };

    return window.fetch(
      url,
      {
        ...options,
        headers: {
          ...authorizationOverrides,
          ...options.headers,
        },
        credentials: "omit",
      },
      ...rest
    );
  }, []);

  const renewToken = useCallback(() => {
    if (tokenIsValid(tokenRef.current)) {
      authenticatedFetch(`/api/token`)
        .then((r) => r.json())
        .then((response) => {
          if (response.token) {
            setToken(response.token);
            return null;
          }
          return Promise.reject();
        })
        .catch(() => {
          setToken(null);
        });
    }
  }, [authenticatedFetch, setToken]);

  useEffect(() => {
    const tokenInterval = setInterval(renewToken, TOKEN_RENEW_INTERVAL);
    return () => clearInterval(tokenInterval);
  }, [renewToken]);

  useEffect(() => {
    try {
      if (token) {
        window.localStorage.setItem(MERCHANT_UPDATE_TOKEN_KEY, token);
      } else {
        window.localStorage.removeItem(MERCHANT_UPDATE_TOKEN_KEY);
      }
    } catch (ex) {
      // ignore
    }
  }, [token]);

  const value = useMemo(() => [token, setToken], [setToken, token]);

  return (
    <TokenState.Provider value={value}>
      <AuthenticatedFetch.Provider value={authenticatedFetch}>{children}</AuthenticatedFetch.Provider>;
    </TokenState.Provider>
  );
}

export const useAuthenticatedFetch = () => useContext(AuthenticatedFetch);
export const useTokenState = () => useContext(TokenState);

export default TokenProvider;
