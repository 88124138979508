import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

const TEMPLATE_STATIC_BASE = "https://storage.googleapis.com/gm-assets/templates/";

function CardView() {
  const { id } = useParams();
  const cardTemplateRef = useRef(null);

  useEffect(() => {
    const cardTemplateElement = cardTemplateRef.current;
    const styleLink = document.createElement("link");
    const scriptTag = document.createElement("script");

    fetch(`/api/cards/${id}`)
      .then((r) => (r.ok ? r.json() : Promise.reject(r)))
      .then(({ data }) => {
        const templateId = data.template_id;
        fetch(`${TEMPLATE_STATIC_BASE}${templateId}/template.html`)
          .then((r) => r.text())
          .then((templateContent) => {
            styleLink.href = `${TEMPLATE_STATIC_BASE}${templateId}/style.css`;
            styleLink.rel = "stylesheet";
            document.head.appendChild(styleLink);

            cardTemplateElement.setTemplateContent(templateContent);
            cardTemplateElement.setTemplateData(data);

            scriptTag.defer = true;
            scriptTag.src = `${TEMPLATE_STATIC_BASE}${templateId}/script.js`;
            document.head.appendChild(scriptTag);
          });
      })
      .catch((ex) => {
        console.warn(ex);
      });
    return () => {
      scriptTag.remove();
      styleLink.remove();
    };
  }, [id]);

  return <card-template key={id} ref={cardTemplateRef} />;
}

export default CardView;
