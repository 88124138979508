import React, { useContext, useState, useCallback, useEffect } from "react";
import { Card, Row, Button, Modal, Typography, Spin, Tooltip, Skeleton, Table, Divider, Form, Select, Input } from "antd";
import { MinusOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";

import { useAuthenticatedFetch } from "../AuthenticatedFetchProvider";
import { SubscriptionContext } from "../contexts/SubscriptionContext";

import "./PlanFeatureMatrix.scss";

const { Title, Text } = Typography;
const { Option } = Select;

const comparePlans = (a, b) => {
  const featureList = Object.entries(b.features)
    .filter(([, d]) => d.display && d.display?.rank)
    .sort((aa, bb) => aa[1].display.rank - bb[1].display.rank);

  const missingFeatures = featureList.filter(([key, data]) => a.features[key].value < data.value);
  const commonFeatures = featureList.filter(([k]) => !Object.prototype.hasOwnProperty.call(Object.fromEntries(missingFeatures), k));

  return { missingFeatures, commonFeatures };
};

const FeatureMatrixModal = ({ plan, activePlan, loading, url, modalError, doneFn, clearFn, setRefillBundle, refillCount, setRefillCount }) => {
  const planId = plan?.id;
  const isDowngrade = activePlan?.display_ranking > plan?.display_ranking;

  const refillBody = () =>
    plan?.features.refills && (
      <Row>
        <Form>
          <Form.Item label="Automatically refill credits when you run out?">
            <Input.Group compact>
              <Form.Item name="credit_bundle_id">
                <Select defaultValue={plan?.features.refills.value[0].id} onChange={(e) => setRefillBundle(e.target.value)} style={{ width: 200 }}>
                  {plan.features.refills.value.map((bundle) => (
                    <Option value={bundle.id} key={bundle.id}>
                      {bundle.amount} credits for ${bundle.price}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="refill_count">
                <Input type="number" defaultValue={refillCount} addonAfter="times" onChange={(e) => setRefillCount(e.target.value)} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </Row>
    );

  const modalBody = () => {
    if (modalError) {
      return (
        <Row>
          <Text>There was an error.Please try again later.</Text>
        </Row>
      );
    }

    if (planId) {
      if (loading) {
        return (
          <Row>
            <Text>Creating Charge...</Text>
            <Spin size="large" />
          </Row>
        );
      }
      if (!isDowngrade) {
        return refillBody();
      }
      return (
        <div>
          <Row>
            <Text>You are downgrading you are going to be losing the following features</Text>
          </Row>
          <Row>
            <ul>
              {activePlan &&
                comparePlans(plan, activePlan).missingFeatures.map(([key, { display, value }]) => (
                  <li key={key}>
                    {display.key}
                    {typeof value === "number" && ` from ${activePlan.features[key].display.value} to ${plan.features[key].display.value}`}
                  </li>
                ))}
            </ul>
          </Row>
          <Divider />
          {refillBody()}
        </div>
      );
    }
    return (
      <div>
        <Title>One More thing...</Title>
        <Text>
          In order to start your subscription you&apos;ll need the store owner to approve the charge. Ask them to login and go to the following url to approve
          the charge.If you have enough permission you can click on the link and approve yourself.
        </Text>
        <Button type="link" href={url}>
          {url}
        </Button>
      </div>
    );
  };

  return (
    <Modal
      visible={planId || url || modalError}
      title="Store Owner Approval"
      onCancel={clearFn}
      onOk={isDowngrade || plan?.features.refills ? doneFn : clearFn}
      okText={(isDowngrade && "Downgrade Now") || (plan?.features.refills && "Subscribe") || "Close"}
      okButtonProps={{
        loading,
      }}
    >
      {modalBody()}
    </Modal>
  );
};

const FeatureTable = ({ plans, activeSubscriptions = null, highlightFeature, highlightFeatureValue, subscribe }) => {
  const activePlan = plans.find(({ id }) => id === activeSubscriptions.find((s) => s.plan_id !== -1)?.plan_id);

  let filteredPlans = plans;
  let featureList =
    plans.length > 0
      ? Object.entries(plans[0]?.features)
          .filter(([, d]) => d.display && d.display?.rank)
          .sort((a, b) => a[1].display.rank - b[1].display.rank)
      : [];
  let missingFeatures = [];
  let commonFeatures = [];

  if (highlightFeature && plans.length) {
    filteredPlans = plans.filter((p) => {
      const planFeatureValue = p.features[highlightFeature]?.value;
      switch (typeof planFeatureValue) {
        case "number":
          return planFeatureValue >= Number(highlightFeatureValue);
        case "boolean":
          return planFeatureValue >= Boolean(highlightFeatureValue);
        default:
          return false;
      }
    });

    if (filteredPlans) {
      featureList = featureList.filter(([k]) => k !== highlightFeature);
      missingFeatures = featureList.filter(([key, data]) => activePlan.features[key].value < data.value);
      commonFeatures = featureList.filter(([k]) => !Object.prototype.hasOwnProperty.call(Object.fromEntries(missingFeatures), k));
    }
  }

  if (activePlan && highlightFeature) filteredPlans.unshift(activePlan);

  const upgradeText = (plan) => {
    if (activePlan) {
      if (plan.id === activePlan?.id) {
        return "Current Plan";
      }
      return activePlan.display_ranking <= plan.display_ranking ? "Upgrade" : "Downgrade";
    }

    return "Subscribe";
  };

  const columns = [
    { dataIndex: "feature", key: "feature", title: "", align: "center" },
    ...filteredPlans.map((p) => ({
      dataIndex: p.id,
      key: p.id,
      align: "center",
      title: (
        <div>
          <Row align="middle" justify="center">
            <Title level={2}>{p.name}</Title>
          </Row>
          <Row align="middle" justify="center">
            <Title level={3}>{`USD $${p.cost}`}</Title>
          </Row>
          <Row align="middle" justify="center">
            <Title level={4}>{`Trial Days ${p.trial_days}`}</Title>
          </Row>
          <Row align="middle" justify="center">
            <Button
              onClick={() => {
                subscribe(p);
              }}
              type="primary"
              disabled={p.id === activePlan?.id}
              size="large"
              block
            >
              {upgradeText(p)}
            </Button>
          </Row>
        </div>
      ),
    })),
  ];

  const rowData = ([featureName, featureValue]) => {
    const row = {
      key: featureName,
      feature: featureValue?.display?.key ? featureValue.display.key : featureName,
    };
    filteredPlans.forEach((plan) => {
      let contents = plan?.features[featureName]?.display?.value ? plan.features[featureName].display.value : plan?.features[featureName]?.value;

      if (typeof contents === "boolean") {
        contents = contents ? (
          <CheckOutlined style={{ color: "#52c41a", fontSize: "20px" }} />
        ) : (
          <CloseOutlined style={{ color: "#eb2f96", fontSize: "20px" }} />
        );
      } else if (plan.features[featureName].display?.info) {
        contents = (
          <span style={{ display: "inline-flex" }}>
            <Tooltip title={plan.features[featureName].display?.info}>
              <InfoCircleOutlined />
            </Tooltip>
            <Text strong>{contents}</Text>
          </span>
        );
      } else {
        contents = contents === null ? <MinusOutlined /> : <Text strong>{contents}</Text>;
      }
      row[plan.id] = contents;
    });
    return row;
  };

  const rows = [
    ...((!highlightFeature && featureList.map(rowData)) || []),
    ...((highlightFeature && rowData([highlightFeature, activePlan.features[highlightFeature]])) || []),
    ...((highlightFeature && missingFeatures.map(rowData)) || []),
    ...((highlightFeature && commonFeatures.map(rowData)) || []),
  ];

  return <Table pagination={false} columns={columns} dataSource={rows} />;
};

function PlanFeatureMatrix({ highlightFeature, featureValue: highlightFeatureValue }) {
  const { plans, activePlan, reload, activeSubscriptions, loaded } = useContext(SubscriptionContext);
  const authenticatedFetch = useAuthenticatedFetch();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalConfirmed, setmodalConfirmed] = useState(false);
  const [url, setUrl] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [refillBundle, setRefillBundle] = useState(null);
  const [refillCount, setRefillCount] = useState(1);

  useEffect(() => {
    if (selectedPlan && modalConfirmed) {
      setModalLoading(true);
      authenticatedFetch("/api/subscriptions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subscription: { plan_id: selectedPlan.id, credit_bundle_id: refillBundle, refill_count: refillCount } }),
      })
        .then(async (response) => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            throw error;
          }
          if (data?.redirect_to) {
            window.location.href = data.redirect_to;
          } else {
            reload();
          }
        })
        .catch((error) => {
          // TODO catch error this.setState({ errorMessage: error.toString() });
          // eslint-disable-next-line no-console
          console.error("There was an error!", error);
          setModalError(error);
        })
        .finally(() => {
          setSelectedPlan(null);
          setmodalConfirmed(false);
          setModalLoading(false);
          setRefillBundle(null);
          setRefillCount(1);
        });
    }
  }, [selectedPlan, modalConfirmed, reload, refillCount, refillBundle, authenticatedFetch]);

  const subscribe = useCallback(
    (plan) => {
      const isDowngrade = activePlan?.display_ranking > plan?.display_ranking;
      if (plan?.features.refills) {
        setRefillBundle(plan.features.refills.value[0].id);
        setRefillCount(1);
      }
      setSelectedPlan(plan);
      if (!isDowngrade && !plan?.features.refills) {
        setmodalConfirmed(true);
      }
    },
    [activePlan]
  );

  if (!loaded) {
    return (
      <Card>
        <Skeleton />
      </Card>
    );
  }

  return (
    <>
      <FeatureMatrixModal
        plan={selectedPlan}
        activePlan={activePlan}
        loading={modalLoading}
        url={url}
        error={modalError}
        refillBundle={refillBundle}
        refillCount={refillCount}
        setRefillBundle={(value) => {
          setRefillBundle(value);
        }}
        setRefillCount={(value) => {
          setRefillCount(value);
        }}
        doneFn={() => {
          if (modalConfirmed) {
            if (selectedPlan) {
              setSelectedPlan(null);
              return;
            }
            if (url) {
              setUrl(null);
              reload();
              return;
            }
            if (modalError) {
              setModalError(null);
            }
          }
          setmodalConfirmed(true);
        }}
        clearFn={() => {
          if (url) {
            reload();
          }
          setSelectedPlan(null);
        }}
      />
      <Card>
        <FeatureTable
          plans={plans}
          activeSubscriptions={activeSubscriptions}
          highlightFeature={highlightFeature}
          highlightFeatureValue={highlightFeatureValue}
          subscribe={subscribe}
        />
      </Card>
    </>
  );
}

export default PlanFeatureMatrix;
